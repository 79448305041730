<template>
  <div class="flex flex-col font-Inter mb-4">
    <div class="flex flex-col mt-10 bg-white shadow border px-6 pt-3">
      <div class="flex flex-row mb-4 items-center">
        <router-link class="mr-2" to="/purchases/order/create">
          <el-button type="primary" icon="el-icon-plus">Créer </el-button>
        </router-link>
        <inputSupplier class="mr-2 flex-1" @supplier="handleSupplier" />
        <el-date-picker
          class="mr-2 w-1/2"
          v-model="listQuery.start"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="Date debut"
        >
        </el-date-picker>
        <el-date-picker
          class="mr-2 w-24"
          v-model="listQuery.end"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="Date fin"
        >
        </el-date-picker>

        <el-button @click="fetchOrders()">Filtrer</el-button>
        <el-button class="">Exporter</el-button>
      </div>

      <el-tabs v-model="activeName" @tab-click="handleTab">
        <el-tab-pane label="Toutes" name="ALL"></el-tab-pane>
        <el-tab-pane
          :label="`En cours (${statist.total_item}) `"
          name="OPEND"
        ></el-tab-pane>
        <el-tab-pane label="Terminées" name="CLOSED"></el-tab-pane>
        <el-tab-pane label="Annulées" name="CANCLED"></el-tab-pane>
      </el-tabs>

      <div class="customer-white">
        <skeleton-list :row="8" v-if="listLoading" />
        <el-table :data="items" v-else style="width: 100%">
          <el-table-column
            prop="purchaseorder_date"
            label="DATE CRéation"
            width="170"
          >
            <template slot-scope="{ row }">
              <span>{{ row.created_at }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="purchaseorder_no" label="N° ORDRE" width="180">
            <template slot-scope="{ row }">
              <router-link :to="`/purchases/order/` + row.bill_id">
                <span class="text-blue-500">{{ row.bill_no }}</span>
              </router-link>
            </template>
          </el-table-column>

          <el-table-column prop="" label="ÉCHEANCE" width="140">
            <template slot-scope="{ row }">
              <div class="flex flex-col">
                <span>{{ row.bill_due_date }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="STATUT" width="160">
            <template slot-scope="{ row }">
              <Status :name="row.status" />
            </template>
          </el-table-column>

          <el-table-column prop="vendor_first_name" label="FOURNISSEUR">
            <template slot-scope="{ row }">
              <div class="flex flex-col">
                <span class="uppercase">{{ row.contact_organization }}</span>
                <span class="text-gray-500 text-xs"
                  >{{ row.contact_phone }}/{{ row.contact_street }}
                </span>
              </div>
            </template>
          </el-table-column>

          <el-table-column width="150" label="TOTAL HT" align="right">
            <template slot-scope="{ row }">
              <span class="">{{ row.sub_total | moneyFilter }} </span>
            </template>
          </el-table-column>
          <!--           <el-table-column width="150" label="TOTAL REMISE" align="right">
            <template slot-scope="{ row }">
              <span class="">{{ row.total | moneyFilter }} </span>
            </template>
          </el-table-column> -->

          <el-table-column width="150" label="TOTAL TTC" align="right">
            <template slot-scope="{ row }">
              <span class="">{{ row.total | moneyFilter }} {{row.currency}} </span>
            </template>
          </el-table-column>

          <el-table-column width="150" label="Actions" align="right">
            <template slot-scope="{ row }">
              <el-button
                @click="exportInvoicePDF(row)"
                size="mini"
                icon="el-icon-printer"
                circle
              >
              </el-button>
              <el-button
                @click="handleView(row)"
                size="mini"
                type="info"
                icon="el-icon-view"
                circle
              >
              </el-button>
              <el-dropdown
                style="margin-left: 10px"
                @command="handleCommand"
                trigger="click"
              >
                <el-button size="mini" icon="el-icon-menu" circle></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="{ get: row }">
                    <i class="el-icon-tickets"></i>
                    Afficher
                  </el-dropdown-item>
                  <el-dropdown-item :command="{ copy: row }">
                    <i class="el-icon-document-copy"></i>
                    Dupliquer
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-if="row.status === 'OPEND' || row.status === 'DRAFT'"
                    :command="{ update: row }"
                  >
                    <i class="el-icon-edit"></i>
                    Modifier
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-if="row.status === 'OPEND' || row.status === 'DRAFT'"
                    :command="{ cancel: row }"
                  >
                    <i class="el-icon-delete"></i>
                    Annuler
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="flex justify-end">
        <pagination
          v-show="listQuery.total > 0"
          :total="listQuery.total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.size"
          @pagination="fetchOrders()"
        />
      </div>
    </div>
    <!-- View PDF -->
    <PDFView :url="urlPDF" :visible="dialogViewPDF" @close="handleClose" />
  </div>
</template>

<script>
import { getAllBill, cancelBill, copyBill, fetchStats } from "@/api/purchase";
import Pagination from "@/components/Pagination";
import { toThousandFilter } from "@/Filters";
import { parseDate } from "@/utils";
import inputSupplier from "@/components/searchSupplier";
import Status from "@/components/Status";
import SkeletonList from "@/components/Skeleton/list";
import { exportOrderAchatPDF } from "@/api/export";
import PDFView from "@/components/PDF/view";

export default {
  name: "Order",
  components: { Pagination, inputSupplier, Status, SkeletonList, PDFView },
  filters: {
    filterTime: parseDate,
    moneyFilter: toThousandFilter,
  },
  data() {
    return {
      orders: [],
      listLoading: false,
      items: [],
      dialogViewPDF: false,
      urlPDF: null,
      activeName: "ALL",
      type: "PURCHASE_ORDER",
      statist: {
        total: 0,
        total_item: 0,
      },
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        sort: "desc",
        sortDir: "createdAt",
        start: undefined,
        end: undefined,
        type: "PURCHASE_ORDER",
        orderno: undefined,
        contactId: undefined,
        status: undefined,
      },
    };
  },
  mounted() {
    this.fetchOrders();
    this.fetchStatOpen();
  },
  methods: {
    async fetchOrders() {
      this.listLoading = true;

      if (this.listQuery.contact === "") {
        this.listQuery.contact = undefined;
      }

      await getAllBill(this.listQuery)
        .then((res) => {
          setTimeout(() => {
            this.items = res.data.items;
            this.listQuery.total = parseInt(res.data.total_items);
            this.listQuery.page = parseInt(res.data.current_page) + 1;
            this.listLoading = false;
          }, 1.5 * 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async fetchStatOpen() {
      await fetchStats(this.type)
        .then((res) => {
          this.statist.total = res.data.total_amount;
          this.statist.total_item = res.data.total_item;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleTab(obj) {
      const name = obj.name;

      switch (name) {
        case "OPEND":
          this.listQuery.status = "OPEND";
          break;
        case "CLOSED":
          this.listQuery.status = "CLOSED";
          break;
        case "CANCELED":
          this.listQuery.status = "CANCELED";
          break;
        default:
          this.listQuery.status = undefined;
      }
      this.fetchOrders();
    },

    handleSupplier(id) {
      this.listQuery.contactId = id;
      this.fetchOrders();
    },
    handleCanceledBill(e) {
      const h = this.$createElement;
      this.$msgbox({
        title: "Facture N° " + e.bill_no,
        message: h("div", null, [
          h("span", null, " Voulez vous vraiment annuler le bon d'achat ?"),
        ]),
        showCancelButton: true,
        showClose: false,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "encours...";
            cancelBill(e.bill_id, this.listQuery.type)
              .then(() => {
                setTimeout(() => {
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);
                  let billItem = this.items.find(
                    (el) => el.bill_id === e.bill_id
                  );
                  billItem.status = "CANCELED";
                  this.fetchStatOpen();
                  this.$message({
                    type: "success",
                    message: "La facture d'achat a été annulée",
                  });
                  done();
                }, 1.5 * 1000);
              })
              .catch((err) => {
                setTimeout(() => {
                  this.$message({
                    message: err.data.message,
                    type: "error",
                  });
                  instance.confirmButtonLoading = false;
                  instance.confirmButtonText = "Confirmer";
                }, 1.5 * 1000);
              });
          } else {
            done();
          }
        },
      }).then(() => {});
    },
    handleCopyBill(e) {
      const h = this.$createElement;
      this.$msgbox({
        title: "Facture N° " + e.bill_no,
        message: h("div", null, [
          h("span", null, " Voulez vous vraiment dupliquer le bon d'achat ?"),
        ]),
        showCancelButton: true,
        showClose: false,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "encours...";
            copyBill(e.bill_id, this.listQuery.type)
              .then((res) => {
                setTimeout(() => {
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);
                  this.$message({
                    type: "success",
                    message: "La facture d'achat a été dupliquée",
                  });
                  this.$router.push(`/purchases/order/${res.data._id}`);
                  done();
                }, 1.5 * 1000);
              })
              .catch((err) => {
                setTimeout(() => {
                  this.$message({
                    message: err.data.message,
                    type: "error",
                  });
                  instance.confirmButtonLoading = false;
                  instance.confirmButtonText = "Confirmer";
                }, 1.5 * 1000);
              });
          } else {
            done();
          }
        },
      }).then(() => {});
    },
    handleCommand(e) {
      if (e.get) {
        this.$router.push(`/purchases/order/${e.get.bill_id}`);
      }

      if (e.cancel) {
        this.handleCanceledBill(e.cancel);
      }

      if (e.update) {
        this.$router.push(`/purchases/order/update/${e.update.bill_id}`);
      }

      if (e.copy) {
        this.handleCopyBill(e.copy);
      }
    },

    exportInvoicePDF(e) {
      exportOrderAchatPDF(e.bill_id)
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res], {
              type: "application/pdf",
            })
          );
          let iframe = document.createElement("iframe"); //load content in an iframe to print later
          document.body.appendChild(iframe);

          iframe.style.display = "none";
          iframe.src = url;
          iframe.onload = function () {
            setTimeout(function () {
              iframe.focus();
              iframe.contentWindow.print();
            }, 1);
          };
        })
        .catch((err) => {
          this.$message({
            message: err.data.message,
            type: "error",
          });
        });
    },
    handleView(e) {
      this.dialogViewPDF = true;
      exportOrderAchatPDF(e.bill_id)
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res], { type: "application/pdf" })
          );
          this.urlPDF = url;
        })
        .catch((err) => {
          this.$message({
            message: err.data.message,
            type: "error",
          });
        });
    },
    handleClose() {
      this.dialogViewPDF = false;
    },
  },
};
</script>

<style></style>
