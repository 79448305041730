<template>
  <div class="flex flex-col font-Inter mb-4">
    <div class="flex flex-col mt-10 bg-white border p-6">
      <div class="flex flex-row mb-4 items-center">
        <router-link class="mr-2" to="/purchases/payment">
          <el-button type="primary" icon="el-icon-plus">Créer </el-button>
        </router-link>
        <inputSupplier class="mr-2 flex-1" @supplier="handleSupplier" />
        <el-input
          class="mr-4 flex-1"
          placeholder="Réference reçu de paiement "
          v-model="listQuery.reference"
          @keydown.native.enter="fetchBill()"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <el-date-picker
          class="mr-2 w-1/2"
          v-model="listQuery.start"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="Date debut"
        >
        </el-date-picker>
        <el-date-picker
          class="mr-2 w-24"
          v-model="listQuery.end"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="Date fin"
        >
        </el-date-picker>

        <el-button class="" @click="fetchBill()">Rechercher</el-button>
      </div>

      <div class="customer-white">
        <skeleton-list :row="8" v-if="listLoading" />
        <el-table :data="items" v-else style="width: 100%">
          <el-table-column
            prop="purchaseorder_date"
            label="DATE Création"
            width="170"
          >
            <template slot-scope="{ row }">
              <span>{{ row.date }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="purchaseorder_no" label="N° REçu" width="180">
            <template slot-scope="{ row }">
              <span class="text-blue-500">{{ row.payment_no }}</span>
            </template>
          </el-table-column>

          <el-table-column label="COMPTE">
            <template slot-scope="{ row }">
              <div class="flex flex-col">
                <span>{{ row.account_name }}</span>
              </div>
            </template>
          </el-table-column>

          <el-table-column width="130" label="STATUT">
            <template slot-scope="{ row }">
              <Status :name="row.status" />
            </template>
          </el-table-column>
          <el-table-column
            prop="vendor_first_name"
            label="FOURNISSEUR"
            min-width="150"
          >
            <template slot-scope="{ row }">
              <div class="flex flex-col">
                <span class="uppercase">{{ row.contact_organization }}</span>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="total_all_taxe"
            label="MONTANT "
            align="right"
          >
            <template slot-scope="{ row }">
              <span class="">{{ row.amount | moneyFilter }} {{row.currency}}</span>
            </template>
          </el-table-column>
          <el-table-column width="120" align="right">
            <template slot-scope="{ row }">
              <el-button
                circle
                size="mini"
                icon="el-icon-view"
                @click="handleView(row.payment_id)"
              ></el-button>
              <el-button
                v-if="row.status === 'PAID'"
                type="danger"
                circle
                size="mini"
                icon="el-icon-delete"
                @click="handleDelete(row)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="flex justify-end">
        <pagination
          v-show="listQuery.total > 0"
          :total="listQuery.total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.size"
          @pagination="fetchInvoices()"
        />
      </div>
    </div>
    <!-- List paiement en Bl -->
    <el-dialog
      title="Facture d'achat"
      :visible.sync="dialogPaymentList"
      width="50%"
      top="4vh"
      :close-on-click-modal="false"
    >
      <div class="customer-white">
        <el-table :data="paymentItems" style="width: 100%">
          <el-table-column prop="billNo" label="N° FACTURE" min-width="250">
          </el-table-column>

          <el-table-column
            prop="Quantité"
            align="right"
            label="MONTANT"
            width="250"
          >
            <template slot-scope="{ row }">
              <span>{{ row.amount | moneyFilter }} {{row.currency}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!-- new Paiement -->
    <!-- Payment en Bl -->
    <el-dialog
      title="Reglèment facture d'achat"
      :visible.sync="dialogPaymentInvoice"
      width="45%"
      top="4vh"
      :close-on-click-modal="false"
    >
      <new-payment />
    </el-dialog>
  </div>
</template>

<script>
import {
  getAllPaymentBill,
  cancelPaymentBill,
  getItemsPayment,
} from "@/api/purchase";
import Pagination from "@/components/Pagination";
import { toThousandFilter } from "@/Filters";
import { parseDate } from "@/utils";
import NewPayment from "./newPayment.vue";
import inputSupplier from "@/components/searchSupplier";
import Status from "@/components/Status";
import SkeletonList from "@/components/Skeleton/list";

export default {
  name: "Bill",
  components: { Pagination, NewPayment, inputSupplier, Status, SkeletonList },
  filters: {
    filterTime: parseDate,
    moneyFilter: toThousandFilter,
  },
  data() {
    return {
      orders: [],

      listLoading: false,

      items: [],
      dialogPaymentList: false,
      dialogPaymentInvoice: false,
      paymentItems: [],
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        sort: "desc",
        sortDir: "createdAt",
        start: undefined,
        end: undefined,
        reference: undefined,
        status: undefined,
        contactId: undefined,
      },
    };
  },
  mounted() {
    this.fetchBill();
  },
  methods: {
    async fetchBill() {
      this.listLoading = true;

      if (this.listQuery.contactId === "") {
        this.listQuery.contactId = undefined;
      }

      await getAllPaymentBill(this.listQuery)
        .then((res) => {
          setTimeout(() => {
            this.items = res.data.items;
            this.listQuery.total = parseInt(res.data.total_items);
            this.listQuery.page = parseInt(res.data.current_page) + 1;
            this.listLoading = false;
          }, 1.5 * 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleDelete(row) {
      this.$confirm(
        "Vous souhaitez vraimment supprimer le document ?",
        `Supprimer le  Paiement N° ${row.payment_no}`,
        {
          distinguishCancelAndClose: true,
          dangerouslyUseHTMLString: true,
          confirmButtonText: "Supprimer",
          showClose: false,
          type: "warning",
          closeOnClickModal: false,
          cancelButtonText: "Annuler",
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = "encours...";
              cancelPaymentBill(row.payment_id).then(() => {
                setTimeout(() => {
                  done();
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);
                  const item = this.items.find(
                    (el) => el.payment_id === row.payment_id
                  );
                  item.status = "CANCELED";

                  this.$message({
                    type: "success",
                    message: " Le paiement annulé avec success",
                  });
                }, 1.5 * 1000);
              });
            } else {
              done();
            }
          },
        }
      ).then(() => {});
    },
    handleView(id) {
      getItemsPayment(id).then((res) => {
        this.paymentItems = res.data;
        this.dialogPaymentList = true;
      });
    },
    handleAdd() {
      this.dialogPaymentInvoice = true;
    },
    handleSupplier(id) {
      this.listQuery.contactId = id;
      this.fetchBill();
    },
  },
};
</script>

<style></style>
