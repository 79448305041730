<template>
  <div class="flex flex-col font-Inter px-10">
    <div class="lg:flex lg:items-center lg:justify-between mb-3">
      <div class="flex-1 min-w-0 flex justify-between py-4">
        <div
          class="mt-1 flex flex-row justify-center items-center sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"
        >
          <h2
            class="text-xl uppercase font-medium leading-7 text-gray-900 sm:text-2xl sm:truncate"
          >
            Reglèment facture N° {{ paymentBill.payment_no }}
          </h2>
        </div>
        <div>
          <el-button icon="el-icon-save" plain>Annuler</el-button>
          <el-button
            :disabled="iscompleted"
            type="primary"
            icon="el-icon-save"
            :loading="loading"
            @click="createData('paymentForm')"
            >Enregistrer</el-button
          >
        </div>
      </div>
    </div>
    <div class="mb-10 overflow-y-auto">
      <div class="flex flex-col mx-auto bg-white p-5 border shadow">
        <el-form
          :model="paymentBill"
          :rules="rules"
          ref="paymentForm"
          :label-position="labelPosition"
        >
          <div class="flex justify-between items-start">
            <div class="flex flex-col w-1/2">
              <search-supplier
                @supplier="handleSelectedSupplier"
                @search-supplier="handleSearchSupplier"
                :customerInfo="customerInfo"
                :suppliers="suppliers"
              />
            </div>
            <div class="flex flex-col justify-end items-end w-1/2">
              <div
                class="text-3xl font-medium leading-7 text-gray-900 sm:text-3xl sm:truncatemt-2 my-2"
              >
                Montant : {{ totalPayment | moneyFilter }} {{user.currency_code}}
              </div>

              <div class="flex flex-row justify-end">
                <el-form-item class="mr-2" label="Date" prop="payment_date">
                  <el-date-picker
                    v-model="paymentBill.payment_date"
                    type="date"
                    placeholder="jj-mm-aaaa"
                    format="dd MMMM yyyy"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="Reference">
                  <el-input v-model="paymentBill.reference"> </el-input>
                </el-form-item>
              </div>

              <el-form-item class="mr-2" label="Compte" prop="account_number">
                <el-select
                  v-model="paymentBill.account_number"
                  placeholder="Choisir le compte"
                  @change="selectAccount"
                >
                  <el-option
                    v-for="item in accounts"
                    :key="item.account_id"
                    :label="`${item.account_name}`"
                    :value="item.numero"
                  >
                  </el-option>
                </el-select>
                <div
                  v-if="paymentBill.balance > 0"
                  class="font-semibold text-red-500 text-base mt-2"
                >
                  Solde : {{ paymentBill.balance | moneyFilter }} {{user.currency_code}}
                </div>
              </el-form-item>

              <el-form-item label="Note">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 3, maxRows: 3 }"
                  placeholder="Entrez quelque chose"
                  v-model="paymentBill.note"
                />
              </el-form-item>
            </div>
          </div>
        </el-form>
        <el-divider></el-divider>
        <div class="flex justify-end mb-2 pr-5">
          <h5 class="font-medium">
            Nombre : {{ paymentBill.items.length }} Facture(s)
          </h5>
        </div>
        <div class="customer-white">
          <el-table
            :data="paymentBill.items"
            style="width: 100%"
            v-loading="listLoading"
          >
            <el-table-column prop="" label="#" width="60">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>

            <el-table-column label="DATE" width="200">
              <template slot-scope="{ row }">
                <span>{{ row.created_at }}</span>
              </template>
            </el-table-column>
            <el-table-column label="DATE D'ECHEANCE" width="200">
              <template slot-scope="{ row }">
                {{ row.due_date }}
              </template>
            </el-table-column>
            <el-table-column prop="bill_no" label="N° FACTURE" min-width="180">
            </el-table-column>
            <el-table-column
              prop="amount_total"
              label="Total"
              width="150"
              align="right"
            >
              <template slot-scope="{ row }">
                <span>{{ row.total | moneyFilter }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="" label="AVANCE" width="150" align="right">
              <template slot-scope="{ row }">
                <span class="font-bold">{{ row.avance | moneyFilter }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="amount_due"
              label="RESTANT À PAYER"
              width="180"
              align="right"
            >
              <template slot-scope="{ row }">
                <div class="ml-8">
                  <el-input-number
                    :controls="false"
                    v-model="row.amount"
                    :min="1"
                    :max="row.total - row.avance"
                  >
                  </el-input-number>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="" label="" align="center" width="60">
              <template slot-scope="{ row }">
                <i
                  @click="handleRemoveItemInvoice(row)"
                  class="el-icon-circle-close text-red-500 text-2xl cursor-pointer mr-2"
                ></i>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  generatePaymentNo,
  getAllBillContact,
  createPaymentSingleBill,
} from "@/api/purchase";
import { fetchMethodPayment } from "@/api/settings";
import { fetchAccounts } from "@/api/account";
import { parseTime, formatDate } from "@/utils";
import { toThousandFilter } from "@/Filters";
import SearchSupplier from "@/components/AddSupplier";
import {mapGetters} from "vuex";
export default {
  name: "CreateInvoice",
  filters: {
    filterTime: parseTime,
    moneyFilter: toThousandFilter,
  },
  components: { SearchSupplier },
  data() {
    return {
      labelPosition: "top",
      loadingCustomer: false,
      listLoading: false,
      suppliers: [],
      loadingCustomerInfo: false,
      accounts: [],
      loading: false,
      modepayment: [],
      customerInfo: {
        contact_id: "",
        phone: "",
        email: "",
        first_name: "",
        last_name: "",
        street: "",
        contact_no: "",
        organization_name: "",
      },
      paymentBill: {
        supplier_id: "",
        note: "",
        payment_date: formatDate(new Date()),
        payment_no: "",
        amount: 0,
        reference: "",
        account_number: "",
        account_name: "",
        items: [],
      },
      rules: {
        payment_date: [
          {
            required: true,
            message: "La date est obligatoire",
            trigger: "change",
          },
        ],
        payment_no: [
          {
            required: true,
            message: "La numéro paiement est obligatoire",
            trigger: "bluer",
          },
        ],
        account_number: [
          {
            required: true,
            message: "Le compte est obligatoire",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
      ...mapGetters(["user"]),
    totalPayment: function () {
      let total = 0;
      this.paymentBill.items.forEach((el) => {
        total += el.amount;
      });
      return total;
    },
    iscompleted: function () {
      if (this.paymentBill.items.length > 0) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.initData();
  },
  methods: {
    async handleSelectedSupplier(id) {
      this.listLoading = true;
      const supplier = this.suppliers.find((c) => c.contact_id === id);
      this.customerInfo = Object.assign({}, supplier);
      this.paymentBill.items = [];
      this.paymentBill.supplier_id = supplier.contact_id;

      await getAllBillContact(id, "BILL")
        .then((res) => {
          setTimeout(() => {
            const items = res.data;
            items.map((e) => {
              this.$set(e, "amount", e.total - e.avance);
              this.paymentBill.items.push(e);
            });

            this.listLoading = false;
          }, 200);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleSearchSupplier(data) {
      this.suppliers = data;
    },

    selectAccount(id) {
      const account = this.accounts.find((el) => el.numero === id);

      this.paymentBill.balance = account.balance;
      this.paymentBill.account_name = account.account_name;
    },

    async getAccounts() {
      await fetchAccounts()
        .then((res) => {
          this.accounts = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    removeAccount(row) {
      const index = this.payment.accounts.indexOf(row);
      this.payment.accounts.splice(index, 1);
    },
    async getMethodPayment() {
      await fetchMethodPayment()
        .then((res) => {
          this.modepayment = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getNextPaymentNo() {
      await generatePaymentNo()
        .then((res) => {
          this.paymentBill.payment_no = res.data.numero;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    initData() {
      this.getNextPaymentNo();
      this.getAccounts();
      this.getMethodPayment();
    },
    createData(paymentForm) {
      this.$refs[paymentForm].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.paymentBill.amount = this.totalPayment;

          createPaymentSingleBill(this.paymentBill)
            .then(() => {
              setTimeout(() => {
                // this.$router.push("/purchase/invoice");
                this.$router.push(`/purchases/index?tab=PAYMENT`);
                this.$message({
                  type: "success",
                  message: "Le paiement a été effectué",
                });
                this.loading = false;
              }, 1.5 * 1000);
            })
            .catch((err) => {
              this.loading = false;
              if (err.data.status === 400) {
                this.$message({
                  message:
                    "Veuillez renseigner les informations obligatoire et les articles",
                  type: "warning",
                });
              } else if (err.data.status === 490) {
                this.$message({
                  message: err.data.message,
                  type: "warning",
                });
              } else {
                this.$message({
                  message: "Erreur serveur",
                  type: "danger",
                });
              }
            });
        }
      });
    },
    handleChange(row) {
      const account = this.accounts.find((el) => el.numero === row.account_id);
      row.account_name = account.account_name;
    },
    handleCleanItem() {},
    handleRemoveItemInvoice(row) {
      const index = this.paymentBill.items.indexOf(row);
      this.paymentBill.items.splice(index, 1);
    },
  },
};
</script>

<style></style>
