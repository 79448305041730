<template>
  <div>
    <PageHeader>
      <div class="text-gray-900 text-2xl font-semibold py-2">
        Gestion des achats
      </div>
    </PageHeader>

    <div class="flex flex-col">
      <div class="bg-white h-12 pt-2 px-6 pb-0">
        <el-tabs v-model="activeName">
          <el-tab-pane label="FACTURES D'ACHATS " name="INVOICE"> </el-tab-pane>
          <el-tab-pane name="ORDER" label="ORDRES D'ACHAT"> </el-tab-pane>
          <el-tab-pane name="PAYMENT" label="RÈGLEMENTS"> </el-tab-pane>
        </el-tabs>
      </div>
      <keep-alive>
        <invoice v-if="activeName === 'INVOICE'" class="px-6" />
        <quote v-if="activeName === 'QUOTE'" class="px-6" />
        <order v-if="activeName === 'ORDER'" class="px-6" />>
        <payment v-if="activeName === 'PAYMENT'" class="px-6" />>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import Invoice from "./invoice";
import Order from "./order";
import Payment from "./payment";

export default {
  components: {
    PageHeader,
    Invoice,

    Order,
    Payment,
  },
  data() {
    return {
      newArticle: false,
      updateArticle: null,
      activeName: "INVOICE",
    };
  },
  watch: {
    activeName(val) {
      this.$router.push(`${this.$route.path}?tab=${val}`);
    },
  },
  created() {
    // init the default selected tab
    const tab = this.$route.query.tab;
    if (tab) {
      this.activeName = tab;
    }
  },
};
</script>

<style lang="scss" scoped></style>
